import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';

// Not using the select but leaving this stuff in here
// const reasonForContactOptions = [
//     {value: "assignment" , text: "An Assignment"},
//     {value: "coaching" , text: "Business Coaching"},
//     {value: "plan" , text: "Business Plan"},
//     {value: "review" , text: "Company Review"},
//     {value: "mentoring" , text: "Mentoring Service"},
//     {value: "project" , text: "A Project"},
//     {value: "SWOT" , text: "SWOT Analysis"},
//     {value: "general" , text: "General Enquiry"},
//<SelectInput reg={register()}  name="company" label="Reason for Contact" type="text" errors={errors.company} options={reasonForContactOptions} />
// ]
// const encode = (data) => {
//     return Object.keys(data)
//         .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//         .join("&");
//   }

const ContactPage = () => {

    const {register, handleSubmit, watch, errors} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('')

    const onSubmit = (data, e) => {

        // fetch("/", {
        //     method: "POST",
        //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //     body: encode({
        //         'form-name': 'contact',
        //         data,
        //       }),
        //   })
        //     .then(() => alert("Success!"))
        //     .catch(error => alert(error));
    
        //   e.preventDefault();

        const response =  axios.post(
            '/forms/contact-form.php',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
        
            if(res.data === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }

    return (
    <Layout pageClass="contact-page">
        <SEO title="Contact Us" />
        <main className="main"> 
            <div className="typography inner">
                <h1>Contact Us</h1>

                    <h2>Your THREE-STEP PROCESS</h2>
                        <ol>
                            <li>A telephone conversation to get an initial understanding of your business</li>
                            <li>A site visit to put your business in context</li>
                            <li>A two-hour face-to-face working session</li>
                        </ol>
                        <p>At the conclusion, you will receive a document highlighting your issues, opportunities and possible actions to address them.</p>


                <h2>Contact Form</h2>
                <p className="center">Fill out the form below and we will get back to you as soon as possible!</p>

                <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="bot-field" />

                    <TextInput reg={register({required: "Name is Required"})}  name="name" label="Name" type="text" errors={errors.name} />

                    <TextInput reg={register()}  name="position" label="Position" type="text" errors={errors.position} />
                    <TextInput reg={register()}  name="company" label="Company" type="text" errors={errors.company} />

                    <TextInput reg={register({required: "Email is Required"})}  name="email" label="Email" type="text" errors={errors.email} />

                    <TextInput reg={register()}  name="phone" label="Phone" type="text" errors={errors.phone} />

                    <TextareaInput reg={register({required: "Enquiry is Required"})}  name="enquiry" label="Enquiry"  errors={errors.enquiry} />

                    <TextareaInput reg={register()}  name="best_contact" label="Best way and time to contact me"  errors={errors.best_contact} />
        
                    <input type="submit" className="submit-btn btn" value="Submit" />
                </form>

                
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
    </Layout>
    )
}

export default ContactPage